import symbols from "../utils/symbols";

export type TPressItem = {
  title: string;
  author: string | null;
  outlet: string;
  link: string;
  publishedOn: string;
  summary: string | null;
  isSelected?: boolean;
  payWall?: boolean;
};

const pressData: Array<TPressItem> = [
  {
    link: `https://www.tampabay.com/news/education/2023/04/26/new-college-of-florida-desantis-tenure-deny-corcoran-trustees/`,
    title: `New College of Florida drama continues as trustees deny tenure for 5 faculty`,
    author: `Ian Hodgson`,
    outlet: `Tampa Bay Times`,
    summary: `New College of Florida’s board of trustees declined to grant tenure to five veteran faculty members on Wednesday, a controversial move that critics said stemmed from inappropriate interference from the Sarasota school’s interim president, Richard Corcoran.`,
    publishedOn: "2023-04-26",
    payWall: false,
    isSelected: true,
  },

  {
    link: `https://www.sfgate.com/news/politics/article/desantis-backed-new-college-board-scraps-5-17920912.php`,
    title: `DeSantis-backed New College board scraps 5 professors tenure`,
    author: `Curt Anderson`,
    outlet: "SFGATE",
    summary: `The New College of Florida trustees now dominated by conservatives appointed by Gov. Ron DeSantis voted Wednesday to deny tenure to five professors, overriding concerns by faculty and students that the decision poses a threat to academic freedom.`,
    publishedOn: "2023-04-26",
    payWall: false,
    isSelected: true,
  },

  {
    link: `https://ncfcatalyst.com/trustee-christopher-rufo-and-new-college-are-being-sued-for-access-to-rufos-personal-phone-records/`,
    title: `Trustee Christopher Rufo and New College are being sued for access to Rufo’s personal phone records`,
    author: "Nickolas Steinig",
    outlet: "NCF Catalyst",
    summary: `New College of Florida Board of Trustees (BOT) member Christopher Rufo and New College of Florida are currently being sued in a joint lawsuit filed on Feb. 21 by the Florida Center for Government Accountability (FLCGA), a nonprofit transparency organization. FLCGA’s suit includes a request for communications that took place on Rufo’s private cellphone during a 17-day period between Jan. 6 and Jan. 23. The nonprofit has a self-described mission of achieving government transparency through “aggressive public records investigations and well-sourced quality reporting.”`,
    publishedOn: "2023-03-07",
    isSelected: false,
    payWall: false,
  },
  {
    title: `Reality of rich academic culture at New College defies DeSantis' belittling, smears`,
    link: `https://www.msnbc.com/alex-wagner-tonight/watch/reality-of-rich-academic-culture-at-new-college-defies-desantis-belittling-smears-164382789960`,
    author: "Alex Wagner",
    outlet: "MSNBC",
    summary: `Alex Wagner travels to Florida's New College to talk in person with students at the center of Governor Ron DeSantis' demagogic campaign against diversity and inclusion in education.`,
    publishedOn: "2023-03-02",
    isSelected: true,
  },

  {
    link: `https://www.msnbc.com/alex-wagner-tonight/watch/new-college-students-steadfast-in-defiance-of-desantis-buzzwords-and-politicized-agenda-164383813885`,
    title: `New College students steadfast in defiance of DeSantis buzzwords and politicized agenda`,
    author: "Alex Wagner",
    summary: `Alex Wagner talks with students at Florida's New College, who remain steadfast in the face of harsh criticism from Governor Ron DeSantis, refusing to be manipulated by the blizzard of buzzwords and political showmanship from DeSantis.`,
    outlet: "MSNBC",
    publishedOn: "2023-03-02",
    isSelected: true,
  },

  {
    title: `Swell of public support buoys New College students resisting DeSantis`,
    author: "Alex Wagner",
    outlet: "MSNBC",
    summary: `Alex Wagner reports on a surge in donations to the New College of Florida student organization working against Ron DeSantis' efforts to strip diversity and inclusion from the school's core culture.`,
    link: `https://www.msnbc.com/alex-wagner-tonight/watch/swell-of-public-support-buoys-new-college-students-resisting-desantis-164465221830`,
    publishedOn: "2023-03-04",
    isSelected: false,
  },

  {
    title:
      "In Florida and at New College, what we lose when we ignore stewardship and competence",
    author: "Aaron Hillegrass",
    outlet: "Tampa Bay Times",
    summary: `The erosion of competence starts at the top, but seldom stops until few people in an organization are worthy of the seat they sit in.`,
    publishedOn: "2023-03-04",
    link: `https://www.tampabay.com/opinion/2023/03/04/florida-new-college-what-we-lose-when-we-ignore-stewardship-competence-column`,
  },

  {
    title:
      "DeSantis' dismantling of New College is 'cancel culture' at its worst",
    author: "Malcolm Lazin",
    outlet: "Herald-Tribune",
    publishedOn: "2023-03-05T05:20",
    summary: `Under the guise of attacking “ideological conformity,” Gov. Ron DeSantis is remaking public education at all levels in Florida.`,
    link: `https://www.heraldtribune.com/story/opinion/columns/your-voice/2023/03/04/desantis-has-hijacked-the-education-dreams-of-new-college-students/69957795007`,
    payWall: true,
  },

  {
    title: `Records request to New College of Florida Regarding Gov. DeSantis' new appointments to the Board of Trustees`,
    author: null,
    outlet: "American Oversight",
    summary: `Records request to the New College of Florida seeking email communications concerning Gov. Ron DeSantis’ new appointments to the Board of Trustees, including Charles Kesler, Matthew Spalding, and Mark Bauerlein.`,
    link: `https://www.americanoversight.org/document/records-request-to-new-college-of-florida-regarding-gov-desantis-new-appointments-to-the-board-of-trustees`,
    publishedOn: "2023-02-09",
  },

  {
    title: `Tiny Public College Known for ‘Free Thinkers’ Is Latest Target for DeSantis`,
    author: `Patricia Mazzei`,
    outlet: `The New York Times`,
    summary: `Joshua Epstein, who is 17 and on schedule to graduate next year after amassing college credits while in high school, said that if anything, he had become more conservative at New College. “I wholeheartedly support any effort to bring conservative students to this campus and to bring more conservative professors,” he said. “I don’t want them to take the school and turn it into a solely conservative institution with only conservative professors. I think that’s the antithesis of what they’re preaching.”`,
    link: `https://www.nytimes.com/2023/02/14/us/ron-desantis-new-college-florida.html`,
    publishedOn: "2023-02-14",
    payWall: true,
  },

  {
    title: `Why America’s Schools Are Getting More Political`,
    link: `https://www.bloomberg.com/opinion/articles/2023-02-14/campaign-2024-why-republicans-and-democrats-disagree-about-schools?sref=EeYbZOAD&leadSource=uverify%20wall`,
    publishedOn: "2023-02-14T07:30",
    outlet: "Bloomberg",
    author: `David A. Hopkins`,
    summary: `He recently appointed six conservative trustees to oversee the New College of Florida, the state’s honors college, with the goal of reforming its curriculum and policies to be less objectionable to the cultural right.`,
    payWall: true,
  },

  {
    title: `Politically connected interim president of New College gets $699,000 salary plus perks`,
    link: `https://www.miamiherald.com/news/local/education/article272481621.html`,
    outlet: "Miami Herald",
    author: "Divya Kumar",
    publishedOn: "2023-02-13T17:45",
    summary: `Jennifer Wright, a parent of a New College student, referenced Corcoran’s handling of a major contract in Jefferson County. “Mr. Corcoran is bad choice for interim president,” Wright said. “He has a terrible record of leadership and questionable moral judgment.”`,
    payWall: false,
  },

  {
    title:
      "New College hires Richard Corcoran as interim president for $699,000 annually",
    author: "Zac Anderson",
    outlet: "The Palm Beach Post",
    summary:
      "The New College of Florida Board of Trustees hired former Florida Education Commissioner Richard Corcoran Monday to serve as the school's interim president at an annual salary of $699,000, more than double the previous president's compensation and an amount that drew concerns from trustees and the public.",
    publishedOn: "2023-02-13T13:09",
    isSelected: false,
    payWall: true,
    link: `https://www.palmbeachpost.com/story/news/politics/2023/02/13/richard-corcoran-will-lead-desantis-overhaul-of-new-college-of-florida/69897573007/`,
  },

  {
    title: `Public Statement of the Faculty of New College of Florida`,
    author: null,
    summary: `Historical experience demonstrates that no institution can alone confront the challenges of well-
    orchestrated, well-funded entities seeking to stifle constitutionally guaranteed protections. Therefore, we are united with other universities and entities that stand strong to protect our
    shared freedoms and human dignity.`,
    outlet: "",
    publishedOn: "2023-02-10",
    link: `https://drive.google.com/file/d/1SRYc621aQI3iYuAIQNyGbqxFJBzpy0F5/view`,
    isSelected: true,
  },

  {
    title: `“The Florida of Today is the America of Tomorrow”: Ron Desantis’s New College Takeover Is Just the Beginning of the Right’s Higher Ed Crusade`,
    author: "Kathryn Joyce",
    outlet: "Vanity Fair",
    summary:
      "Republican politicians and right-wing activists are transforming one of the Sunshine State’s liberal arts schools into the “Hillsdale of the South,” a strategy that could be replicated across the country. As one New College alum tells Vanity Fair, “I weep for our nation if DeSantis wins a presidential bid.”",
    link: `https://www.vanityfair.com/news/2023/02/ron-desantis-new-college-florida?redirectURL=https://www.vanityfair.com/news/2023/02/ron-desantis-new-college-florida`,
    publishedOn: "2023-02-10",
    payWall: true,
    isSelected: true,
  },

  {
    title: `Jews, bicycle riders and trans folk`,
    author: `Neil Steinberg`,
    outlet: "Chicago Sun Times",
    summary: `Why are trans rights so endlessly agonized over? Illumination comes from an unexpected source.`,
    link: `https://chicago.suntimes.com/columnists/2023/2/9/23591710/antisemitism-trans-rights-lgbtq-desantis-putin-bigotry-republicans-florida-sanders-steinberg?fbclid=IwAR0w1PezFQf-YgTIwvJzRk4YqcEzm1mULYfvgO8-cqVPNptVByQsYzUFhy8`,
    publishedOn: "2023-02-09T10:32",
    payWall: false,
  },

  {
    title: `Conservatives angry about school ‘indoctrination’ are telling on themselves`,
    author: `Jan-Werner Müller`,
    outlet: "The Guardian",
    summary: `Ideologues like Ron DeSantis aren’t angry at politics in education; they’re angry their politics don’t have a monopoly.`,
    link: "https://amp.theguardian.com/commentisfree/2023/feb/04/conservatives-ron-desantis-florida-education",
    publishedOn: "2023-02-04T06:13",
    payWall: false,
  },

  {
    title: `The Changes to AP African American Studies Are “Absolutely Political”: A Former College Board Worker Speaks Out`,
    author: `Jess DuBois`,
    outlet: "Left Voice",
    summary: `There are also many problems facing U.S. educators beyond AP African American Studies, including “Don’t Say Gay” bills, last week’s political coup of The New College of Florida, and attacks on the rights of trans children and the rights of their teachers to protect them. All of these issues require workers to organize together to fight back, to tell governments and companies like the College Board that this is unacceptable.`,
    link: `https://www.leftvoice.org/the-changes-to-ap-african-american-studies-are-absolutely-political-a-former-college-board-worker-speaks-out/`,
    publishedOn: "2023-02-04",
    payWall: false,
  },

  {
    title: `Our View: DeSantis’ education ideology suits him, not public students`,
    author: null,
    outlet: `The Durango Herald`,
    summary: `At a trustee meeting, New College President Patricia Okker criticized the new direction being pushed as a “hostile takeover.” She was then fired. Okker said, “I’m going to say publicly, I do not believe that students are being indoctrinated at New College.”`,
    link: `https://www.durangoherald.com/articles/our-view-desantis-education-ideology-suits-him-not-public-students/`,
    publishedOn: "2023-02-05T04:30",
    payWall: false,
  },

  {
    title: `Unpacking the paradox of conservatives cheering DeSantis’ use of government power`,
    outlet: `Las Vegas Review-Journal`,
    author: `Victor Joecks`,
    summary: `On Tuesday, the new-look board fired President Patricia Okker, replacing her with a former Republican speaker of the Florida House. The board also directed the staff to produce a plan to eliminate diversity, equity and inclusion, known DEI, from the university.`,
    link: `https://www.reviewjournal.com/opinion/opinion-columns/victor-joecks/victor-joecks-unpacking-the-paradox-of-conservatives-cheering-desantis-use-of-government-power-2723978/`,
    publishedOn: "2023-02-04T21:00",
    payWall: false,
  },

  {
    title: `The uneducated attack on woke in education`,
    outlet: `Orlando Sentinel`,
    author: `Emily Nodine`,
    summary: `“You don’t know what you are talking about, and you do not belong in this class.” That was some of the first feedback I received as a new student at New College of Florida, which is currently being subjected to top-down radical change as part of Gov. Ron DeSantis’s attack on educational institutions that are “hostile to academic freedom.”`,
    link: `https://www.orlandosentinel.com/opinion/guest-commentary/os-op-attack-on-woke-new-college-20230204-iop3bstucjf4rc5wux2rxdeqhi-story.html`,
    publishedOn: `2023-02-05T05:30`,
    payWall: true,
    isSelected: true,
  },

  {
    title: `What is behind Ron DeSantis’s Stop-Woke Act?`,
    outlet: "The Guardian",
    author: `Cas Muddle`,
    summary: `In addition to his Stop-Woke (Wrongs to Our Kids and Employees) Act, which prohibits educational institutions and businesses from teaching students and employees anything that would cause anyone to “feel guilt, anguish or any form of psychological distress” due to their race, color, sex or national origin, he has barred University of Florida professors from giving evidence against the state’s voting law, claimed that professors at public colleges have no right to freedom of speech, and organizing a “hostile takeover” of the New College of Florida, one of the best liberal arts colleges in the country.`,
    link: `https://www.theguardian.com/commentisfree/2023/feb/06/what-is-behind-ron-desantis-stop-woke-act`,
    publishedOn: "2023-02-06T06:11",
    payWall: false,
  },

  {
    title: `Schools and universities are ground zero for America’s culture war`,
    outlet: "The Guardian",
    author: `Moira Donegan`,
    summary: `He’s started with the New College of Florida, a small liberal arts honors college with an artsy reputation. There, DeSantis installed a new board made up of Christian college administrators, Republican think-tank denizens, and the right-wing online influencer Christopher Rufo.`,
    link: `https://www.theguardian.com/commentisfree/2023/feb/05/schools-and-universities-are-ground-zero-for-americas-culture-war`,
    publishedOn: "2023-02-05T11:14",
    payWall: false,
    isSelected: true,
  },

  {
    title: `DeSantis ramps up ‘war on woke’ with new attacks on Florida higher education`,
    outlet: "The Guardian",
    author: "Joseph Contreras",
    summary: `“It’s a wake-up call to stand up for educational freedom and for what’s right, and I’m absolutely re-energized,” said Obraud. “It’s solidified my resolve to stand up and push back against this because these politicians and [new] trustees don’t have the interests of students, their parents or faculty members at heart.”`,
    link: `https://www.theguardian.com/us-news/2023/feb/05/ron-desantis-war-on-woke-florida-higher-education-new-college`,
    publishedOn: "2023-02-05T05:00",
    payWall: false,
  },

  // {
  //   title: `The Hill’s Morning Report — What now for US-China relations?`,
  //   outlet: "The Hill",
  //   author: "Alexis Simendinger and Kristina Karisch",
  //   summary: `Republican lawmakers across the country, meanwhile, are targeting education policies, none more so than Florida Gov. Ron DeSantis, who has made headlines opposing College Board courses and overhauling the board of New College of Florida.`,
  //   link: `https://thehill.com/newsletters/morning-report/3845137-the-hills-morning-report-what-now-for-us-china-relations/`,
  //   publishedOn: ""
  // },
  {
    title: `Florida is trying to roll back a century of gains for academic freedom`,
    outlet: "The Washington Post",
    author: "Glenn C. Altschuler and David Wippman",
    summary: `In January, Gov. Ron DeSantis (R-Fla.) announced six new appointees to the board of New College of Florida, a small liberal arts institution in Sarasota. The team’s apparent mandate, which conservative activist Christopher Rufo—one of the new board members—described as a ${symbols.openQuote}hostage-rescue operation,${symbols.closeQuote} is ${symbols.openQuote}to change everything from the school’s curriculum to its departments and its faculty.${symbols.closeQuote}`,
    link: `https://www.washingtonpost.com/made-by-history/2023/02/06/academic-freedom-florida/`,
    publishedOn: "2023-02-06T06:00",
    payWall: true,
  },

  {
    title: `Inside the “hostile takeover”: an autopsy of the Jan. 31 Board of Trustees meeting`,
    outlet: "NCF Catalyst",
    summary: null,
    author: "Sophia Brown",
    link: `https://ncfcatalyst.com/inside-the-hostile-takeover-an-autopsy-of-the-jan-31-board-of-trustees-meeting/`,
    publishedOn: "2023-02-04",
    isSelected: false,
    payWall: false,
  },

  {
    title: `Florida Has a Right to Destroy its Universities`,
    outlet: `The Atlantic`,
    author: "Tom Nichols",
    summary: `Florida’s governor, Ron DeSantis, has set out to ruin one of Florida’s public colleges. He’s appointed several board members to the ideologically progressive New College of Florida with, apparently, a mandate to somehow rebuild it and thus save it from its dreaded wokeification.`,
    link: `https://www.theatlantic.com/newsletters/archive/2023/01/florida-desantis-universities/672898`,
    publishedOn: "2023-01-30",
    payWall: true,
  },

  {
    title: `DeSantis hedges on voucher funding in Florida education budget proposal`,
    outlet: `Tampa Bay Times`,
    author: `Jeffrey S. Solocheck`,
    summary: `As New College of Florida receives national attention for Gov. Ron DeSantis’ push to remake it as a conservative bastion, the Herald-Tribune offers some insights into what the school has been like to this point.`,
    link: `https://www.tampabay.com/news/education/2023/02/02/desantis-hedges-voucher-funding-florida-education-budget-proposal/`,
    publishedOn: "2023-02-02T07:30",
    payWall: true,
  },

  // {
  //   title: `Amid upheaval, New College could secure $15M through Governor’s budget`,
  //   outlet: "Florida Politics",
  //   author: "Jacob Ogles",
  //   summary: `Gov. Ron DeSantis’ proposed budget for the 2023-24 fiscal year includes $15 million for the Sarasota institution. That includes $10 million in recurring funds, and another $5 million in one-time spending for operational enhancements “as determined by the President and Board of Trustees.”`,
  //   link: `https://floridapolitics.com/archives/585131-amid-upheaval-new-college-could-secure-15m-through-governors-budget/`,
  //   publishedOn: "2023-02-01"
  // },

  {
    title: `DeSantis targets New College of Florida. Here's 7 things to know about the school`,
    outlet: "Herald-Tribune",
    author: "Cheryl McCloud",
    summary: `Gov. Ron DeSantis has blasted Florida universities, and New College in particular, for their diversity, equity and inclusion initiatives. He also accused New College of teaching theories that have become major conservative talking points in recent years, including critical race theory (CRT) and gender theories.`,
    link: `https://www.heraldtribune.com/story/news/education/2023/02/01/new-college-of-florida-liberal-arts-school-gov-ron-desantis-wants-to-change/69861478007/`,
    publishedOn: "2023-02-01T09:34",
    payWall: true,
  },

  // {
  //   title: `Maybe New College of Florida needs a new, old name`,
  //   outlet: "Tampa Bay Times",
  //   author: "Tom Fillion",
  //   summary: `I “woke” up this morning and had a progressive idea to rename New College of Florida. We should give it a creative new name that better reflects Florida’s emphasis on free thought and innovation. So let’s now call it “Old College”!`,
  //   link:
  //   publishedOn: "2023-02-02",
  // },

  {
    title: `New College president ousted, replaced by former education commissioner`,
    outlet: "Fox 51 WOGX",
    author: null,
    summary: `Before the vote on Okker’s removal, trustee Grace Keenan, who is New College’s student-body president, was among the trustees who argued that Okker should be able to keep her job. "I think you’ve done an amazing job," Keenan told Okker. "I think you are an incredible person to lead us through these changes, and I hope you stay."`,
    publishedOn: "2023-02-01T20:00",
    isSelected: false,
    link: `https://www.wogx.com/news/new-college-president-ousted-replaced-by-former-education-commissioner`,
  },

  {
    title: `Students and their supporters decry big changes coming to New College of Florida`,
    outlet: "WUSF Public Media",
    author: "Cathay Carter",
    summary: `${symbols.openQuote}I learned how to take care of my body and my mind, since this is an institution that focuses on quality of work and thought over efficiency,${symbols.closeQuote} González told the crowd. ${symbols.openQuote}And I learned what my professors thought of my progress as a student when I read their narrative evaluations as opposed to arbitrary letter grades and numbered scores in a one size fits all system.${symbols.closeQuote}`,
    publishedOn: "2023-02-01T10:49",
    isSelected: false,
    link: "https://wusfnews.wusf.usf.edu/education/2023-02-01/students-supporters-decry-big-changes-new-college-of-florida",
  },

  {
    title: `Swift changes at New College of Florida sets the stage for new leadership`,
    outlet: "WTSP",
    link: `https://www.wtsp.com/article/news/local/sarasotacounty/new-college-of-florida-leadership/67-581f5812-c26e-417b-8099-d3b612eed846`,
    author: "Adaure Achumba",
    summary: `"They're career politicians. In a lot of ways it's clear that they're using students as political pawns to further their agenda to restrict academic freedom," Madison Markhum, a student at New College of Florida, said.`,
    publishedOn: "2023-02-01T18:07",
    isSelected: false,
  },

  {
    title: `Ron DeSantis announces plan to block DEI programs in state colleges`,
    outlet: "The Guardian",
    summary: `The Florida governor’s latest attack comes on the heels of a ban on an AP course in African American studies in high schools`,
    link: `https://www.theguardian.com/us-news/2023/feb/02/ron-desantis-block-dei-program-state-colleges-florida`,
    author: "Guardian staff and agencies",
    publishedOn: "2023-02-02T18:00",
    isSelected: false,
  },

  {
    title:
      "‘Hostile takeover’: The tiny Florida university targeted by Ron DeSantis",
    outlet: "The Guardian",
    author: "Joseph Contreras",
    link: "https://www.theguardian.com/us-news/2023/jan/29/ron-desantis-florida-university-new-college-woke-war",
    publishedOn: "2023-01-29",
    summary: `Academics specializing in issues related to higher education say they have never seen a scorched-earth assault on a college or university for apparently political reasons that remotely approaches what is facing New College today.`,
    isSelected: false,
    payWall: false,
  },

  {
    title: `New College: Abandonment Of Due Process`,
    outlet: "Forbes",
    author: "Lucie Lapovsky",
    summary: `What we saw today at the Board of Trustees meeting were actions taken with almost no conversation among the board. This is especially surprising in Florida which has sunshine laws which require all board actions to occur in open meetings and prohibits more than two board members from meeting together as that would be construed as a meeting.`,
    link: "https://www.forbes.com/sites/lucielapovsky/2023/01/31/new-college-abandonment-of-due-process/",
    publishedOn: "2023-01-31",
    payWall: true,
  },

  {
    title: `New College gives every student a chance to thrive. Isn't that worth saving?`,
    outlet: "Herald-Tribune",
    author: "Tamara Solum and Kari Solum",
    summary: `If the new board follows through with their plan for this needless transformation, it will deprive students of the opportunities that have become synonymous with a New College education.`,
    link: "https://www.heraldtribune.com/story/opinion/columns/your-voice/2023/01/30/new-college-allows-every-student-to-grow-and-thats-worth-protecting-desantis-trustees/69853197007/",
    publishedOn: "2023-01-30T05:43",
    isSelected: false,
  },

  {
    title:
      "DeSantis wants $10 million in recurring funding for New College transformation",
    author: "Zac Anderson",
    outlet: "Herald-Tribune",
    link: "https://www.heraldtribune.com/story/news/politics/2023/01/31/ron-desantis-wants-funding-to-overhaul-new-college-of-florida/69853999007/",
    summary: `Gov. Ron DeSantis blasted New College of Florida Tuesday on the cusp of a big Board of Trustees meeting, saying the school has been too focused on racial and "gender ideology" and will be reformed by a new board he put in place, which is getting big money to recruit new faculty.`,
    publishedOn: "2023-01-31T10:56",
    isSelected: false,
  },
];

export default pressData;
