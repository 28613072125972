import * as classes from "./press.module.scss";
import React from "react";
import Layout from "../components/ui/Layout";
import Heading from "../components/ui/Heading";
import EmailLink from "../components/ui/EmailLink";
import emailsData from "../data/emailsData";
import pressData, { TPressItem } from "../data/pressData";
import LinkedText from "../components/ui/LinkedText";
import DateDisplay from "../components/ui/DateDisplay";
import { graphql, PageProps } from "gatsby";
import GAScripts from "../components/GAScripts";
import Column from "../components/ui/Column";

const PressItem = ({ item }: { item: TPressItem }) => (
  <LinkedText external={true} to={item.link}>
    <article className={classes["pressItem"]}>
      <div className={classes["title"]}>
        <div>{item.title}</div>
      </div>
      <div className={classes["author"]}>
        <div className={classes["publishedOn"]}>
          <DateDisplay isoString={item.publishedOn} />
        </div>
        {item.outlet || item.author ? " - " : null} {item.outlet}
        {item.author && <> - By {item.author}</>}{" "}
        {item.payWall && (
          <small className={classes["paywallWarning"]}>paywall</small>
        )}
      </div>

      <p className={classes["summary"]}>{item.summary}</p>
    </article>
  </LinkedText>
);

const PressPage = () => {
  const selectedPress = pressData.filter((item) => item.isSelected);
  const morePress = pressData
    .filter((item) => !item.isSelected)
    .sort(
      (a, b) =>
        //@ts-ignore
        new Date(b.publishedOn) - new Date(a.publishedOn)
    );

  return (
    <Layout className={classes["pressPage"]}>
      <Column>
        {/* <Heading>Press</Heading> */}
        <p>
          If you're a member of the media and want to talk, email us at{" "}
          <EmailLink address={emailsData.studentsPress} />.
        </p>

        <div>
          <section>
            <Heading>Selected Press</Heading>
            {selectedPress.map((item, i) => (
              <PressItem key={i} item={item} />
            ))}
          </section>

          <section>
            <Heading>More Press</Heading>
            {morePress.map((item, i) => (
              <PressItem key={i} item={item} />
            ))}
          </section>
        </div>
      </Column>
    </Layout>
  );
};

export const query = graphql`
  query PressPage {
    siteBuildMetadata {
      buildTime
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.PressPageQuery>) => (
  <>
    <title>{`${data.site?.siteMetadata?.title} - Press`}</title>
    <meta name="description" content="Save New College" />
    <GAScripts />
    {/* <script
      async
      src="https://platform.twitter.com/widgets.js"
      charSet="utf-8"
    ></script> */}
  </>
);

export default PressPage;
