import { DateTime } from "luxon";
import React from "react";

const DateDisplay = ({ isoString }: { isoString: string }) => {
  const dt = DateTime.fromISO(isoString);

  if (!dt.isValid) {
    console.warn(`invalid date: ${isoString}`);
  }

  return <span>{dt.toLocaleString(DateTime.DATE_MED)}</span>;
};

export default DateDisplay;
