import React from "react";
import * as classes from "./EmailLink.module.scss";
import LinkedText from "./LinkedText";

const EmailLink = ({ address }: { address: string }) => (
  <LinkedText
    external={true}
    to={`mailto:${address}`}
    // className={classes["emailLink"]}
  >
    {address}
  </LinkedText>
);

export default EmailLink;
